// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-botschafter-js": () => import("./../../../src/pages/botschafter.js" /* webpackChunkName: "component---src-pages-botschafter-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-suche-sportarten-js": () => import("./../../../src/pages/suche/sportarten.js" /* webpackChunkName: "component---src-pages-suche-sportarten-js" */),
  "component---src-pages-suche-vereine-js": () => import("./../../../src/pages/suche/vereine.js" /* webpackChunkName: "component---src-pages-suche-vereine-js" */),
  "component---src-pages-vereine-neu-js": () => import("./../../../src/pages/vereine/neu.js" /* webpackChunkName: "component---src-pages-vereine-neu-js" */),
  "component---src-templates-sportart-pages-js": () => import("./../../../src/templates/sportart-pages.js" /* webpackChunkName: "component---src-templates-sportart-pages-js" */),
  "component---src-templates-sportarten-pages-js": () => import("./../../../src/templates/sportarten-pages.js" /* webpackChunkName: "component---src-templates-sportarten-pages-js" */),
  "component---src-templates-static-pages-js": () => import("./../../../src/templates/static-pages.js" /* webpackChunkName: "component---src-templates-static-pages-js" */)
}

