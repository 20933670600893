/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import './src/styles/app.scss';
// <script async id="google-maps-test" src="https://maps.googleapis.com/maps/api/js?key=AIzaSyCMVEvfMu4fOt4PWMd7E3J5pBpm9Bp-_nQ&amp;libraries=places"></script>

/**
 * Loads the Gooogle Maps library.
 *
 * @param {string} src – Endpoint of the Google Maps library .
 * @param {Object} position – An element within the document.
 * @param {string} id – Id for the <script> element which will be created.
 */
function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

/**
 * Called when the Gatsby browser runtime first starts.
 */
export function onClientEntry() {
  console.log('Tinongo App started!');

  loadScript(
    `https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_MAPS_API_KEY}&libraries=places`,
    document.querySelector('head'),
    'google-maps-lib'
  );
}
